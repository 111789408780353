const OverlayPlugin = {
  install(Vue) {
    Vue.prototype.$showOverlay = function() {
      this.$store.dispatch('showOverlay');
    };
    Vue.prototype.$hideOverlay = function() {
      this.$store.dispatch('hideOverlay');
    };
  }
};

export default OverlayPlugin