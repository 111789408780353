export function calculateQuantityProduct(quantityPcs, quantityKilogram, quantityGram, weight) {
    quantityPcs === '' ? quantityPcs = 0 : quantityPcs
    quantityKilogram === '' ? quantityKilogram = 0 : quantityKilogram
    quantityGram === '' ? quantityGram = 0 : quantityGram
    const convertQuantityPcs = typeof quantityPcs === 'string' ? parseInt(quantityPcs.split(',').join('')) : quantityPcs;
    const convertQuantityKilogram = typeof quantityKilogram === 'string' ? parseInt(quantityKilogram.split(',').join('')) : quantityKilogram;
    const convertQuantityGram = typeof quantityGram === 'string' ? parseInt(quantityGram.split(',').join('')) : quantityGram;

    let kg = parseFloat(((convertQuantityPcs * weight + convertQuantityKilogram) + convertQuantityGram / 1000).toFixed(3));
    let pcs = Math.floor(kg / weight);
    let gram = parseFloat(((convertQuantityPcs * weight + convertQuantityKilogram) * 1000 + convertQuantityGram).toFixed(3));

    if (weight === 0) {
      pcs = convertQuantityPcs
      kg = 0
      gram = 0
      return { pcs, kg, gram }
    }

    return { pcs, kg, gram };
}

// console.log(calculateQuantityProduct(0, 5+41.535, 0, 25))