import Vue from 'vue'
import Router from 'vue-router'
import store from './../store'
import Swal from 'sweetalert2'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views Interface
const ChangeOrderStatus = () => import('@/views/interface/Change-Order-Status')

// Views Stocks
const AdjustmentStock = () => import('@/views/stock/AdjustmentStock')
const ConfirmationAdjustment = () => import('@/views/stock/ConfirmationAdjustment')
const ConfirmationGoodsReceipt = () => import('@/views/stock/ConfirmationGoodsReceipt')
const ConfirmationGoodsReceiptPo = () => import('@/views/stock/ConfirmationGoodsReceiptPo')
const ConfirmationProductionReceive = () => import('@/views/stock/ConfirmationProductionReceive')
const finditems_movestock = () => import('@/views/stock/finditems_movestock')
const finditems = () => import('@/views/stock/finditems')
const HistoryAdjustmentProduct = () => import('@/views/stock/HistoryAdjustmentProduct')
const HistoryMovementProduct = () => import('@/views/stock/HistoryMovementProduct')
const LocationList = () => import('@/views/stock/LocationList')
const LowExpirationProduct = () => import('@/views/stock/LowExpirationProduct')
const GoodsReceiptInventory = () => import('@/views/stock/GoodsReceiptInventory')
const GoodsReceiptProduction = () => import('@/views/stock/GoodsReceiptProduction')
const GoodsReceiptPurchaseOrder = () => import('@/views/stock/GoodsReceiptPurchaseOrder')
const MoveReceiptPurchaseOrder = () => import('@/views/stock/MoveReceiptPurchaseOrder')

//View Management
const userPermission = () => import('@/views/management/userpermission')

// View Report
const editforecast = () => import('@/views/report/editforecast')
const predictproduction = () => import('@/views/report/predictproduction')
const reportreceipts = () => import('@/views/report/report_receipts')
const reportordercancel = () => import('@/views/report/report_ordercancel')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

Vue.use(Router)

const router = new Router(
  {
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({
      y: 0
    }),
    routes: configRoutes()
  },
)
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/pages/login')
  } else {
    next()
  }
})
export default router;

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/pages/login',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'management',
          redirect: '/management/userpermission',
          name: 'Management',
          meta: {
            requiresAuth: true,
          },
          beforeEnter: (to, from, next) => {
            store.dispatch('loadUserPriority').then(() => {
              let numPriority = [1].includes(parseInt(store.getters.getPriority))
              if (numPriority) {
                next()
              } else {
                Swal.fire(
                  'Permission',
                  'Your access rights are not authorized.',
                  'warning'
                )
                next(from.path)
              }
            }).catch(() => {
              Swal.fire(
                'Permission',
                'Failed to load user permissions.',
                'error'
              )
              next(from.path)
            })
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
              path: 'userpermission',
              name: 'User Management',
              component: userPermission,
            }
          ]
        },
        {
          path: 'report',
          redirect: '/report/editforecast',
          name: 'Report',
          meta: {
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1,2,5].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'editforecast',
              name: 'Edit Forecast',
              component: editforecast
            },
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1,2,5].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'predictproduction',
              name: 'Predict Production',
              component: predictproduction
            },
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1,2,4,5].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'report_receipts',
              name: 'Report Receipts',
              component: reportreceipts
            },
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1,2,4,5].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'report_ordercancel',
              name: 'Report Order Cancel',
              component: reportordercancel
            }
          ]
        },
        {
          path: 'stock',
          redirect: '/stock/finditems',
          name: 'Stock',
          meta: {
            requiresAuth: true
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'locations/list',
              name: 'Location',
              component: LocationList
            },
            {
              path: 'goods_receipt_production',
              name: 'Goods Receipt Production',
              component: GoodsReceiptProduction
            },
            {
              path: 'goods_receipt_inventory',
              name: 'Goods Receipt Inventory',
              component: GoodsReceiptInventory
            },
            {
              path: 'goods_receipt_purchase_order',
              name: 'Goods Receipt PO',
              component: GoodsReceiptPurchaseOrder
            },
            {
              path: 'put_away_stock',
              name: 'Move Stock PO',
              component: MoveReceiptPurchaseOrder
            },
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'confirm_goods_receipt_inventory',
              name: 'Confirm Goods Receipt',
              component: ConfirmationGoodsReceipt
            },
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'confirm_goods_receipt_production',
              name: 'Confirm Goods Receipt Production',
              component: ConfirmationProductionReceive
            },
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'confirm_goods_receipt_po',
              name: 'Confirm Goods Receipt PO',
              component: ConfirmationGoodsReceiptPo
            },
            {
              beforeEnter: (to, from, next) => {
                store.dispatch('loadUserPriority').then(() => {
                  let numPriority = [1].includes(parseInt(store.getters.getPriority))
                  if (numPriority) {
                    next()
                  } else {
                    Swal.fire(
                      'Permission',
                      'Your access rights are not authorized.',
                      'warning'
                    )
                    next(from.path)
                  }
                }).catch(() => {
                  Swal.fire(
                    'Permission',
                    'Failed to load user permissions.',
                    'error'
                  )
                  next(from.path)
                })
              },
              path: 'confirm_adjustment',
              name: 'Confirm Adjustment',
              component: ConfirmationAdjustment,
            },
            {
              path: 'finditems_movestock/:id',
              meta: {
                label: 'Move stock'
              },
              name: 'finditems_movestock',
              component: finditems_movestock
            },
            {
              path: 'finditems',
              name: 'Find Items',
              component: finditems
            },
            {
              path: 'adjustmentstock',
              name: 'Adjustment Stock',
              component: AdjustmentStock
            },
            {
              path: 'lowexpiration',
              name: 'Low expiration',
              component: LowExpirationProduct
            },
            {
              path: 'history_movement',
              name: 'History Movement',
              component: HistoryMovementProduct
            },
            {
              path: 'history_adjustment',
              name: 'History Adjustment',
              component: HistoryAdjustmentProduct
            }
          ]
        },
        {
          path: 'interface',
          redirect: '/interface/INF-PUR',
          name: 'Interface',
          meta: {
            requiresAuth: true
          },
          beforeEnter: (to, from, next) => {
            store.dispatch('loadUserPriority').then(() => {
              let numPriority = [1,5].includes(parseInt(store.getters.getPriority))
              if (numPriority) {
                next()
              } else {
                Swal.fire(
                  'Permission',
                  'Your access rights are not authorized.',
                  'warning'
                )
                next(from.path)
              }
            }).catch(() => {
              Swal.fire(
                'Permission',
                'Failed to load user permissions.',
                'error'
              )
              next(from.path)
            })
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'Change-Order-Status',
              name: 'Change-Order-Status',
              component: ChangeOrderStatus
            }
          ]
        }
      ],
    },
    {
      path: '*',
      redirect: '/'
    }, // otherwise redirect to home

    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

