import Vue from 'vue'
import App from './App'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { Row, Column } from 'vue-grid-responsive'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from "axios"
import Vue2Filters from 'vue2-filters'
import vSelect from "vue-select"
import VueSweetalert2 from 'vue-sweetalert2'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import JsonExcel from "vue-json-excel"
import Multiselect from 'vue-multiselect'
import OverlayPlugin from './plugins/overlayPlugin'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueTheMask from 'vue-the-mask'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.css'
import "vue-multiselect/dist/vue-multiselect.min.css"
import "vue-select/dist/vue-select.css"
import 'sweetalert2/dist/sweetalert2.min.css'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

// customize date format in utils folder
import * as dateUtils from './utils/dateUtils'
import { calculateQuantityProduct } from './utils/calibrateWeight.js'

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("v-select", vSelect)
Vue.component('row', Row)
Vue.component('column', Column)
Vue.component("downloadExcel", JsonExcel)
Vue.component('multiselect', Multiselect)

Vue.use(OverlayPlugin)
Vue.use(VueTheMask)
Vue.use(Vue2Filters)
Vue.use(VueSweetalert2)
Vue.use(CoreuiVue, axios)
Vue.config.performance = true
Vue.prototype.$dayjs = dayjs
Vue.prototype.$axios = axios
Vue.prototype.$calibrateWeight = calculateQuantityProduct
Vue.prototype.$log = console.log.bind(console)

Vue.filter('formatDate', (date, functionName) => {
  if (dateUtils[functionName] && typeof dateUtils[functionName] === 'function') {
    return dateUtils[functionName](date);
  }
  return date // Return the original date if the function is not found
})

Vue.prototype.$http = axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
