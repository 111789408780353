<template>
  <div id="app">
    <Overlay />
    <router-view/>
  </div>
</template>

<script>
import Overlay from './components/Overlay.vue';
import { mapState } from 'vuex';

export default {
  name: "App",
  components: {
    Overlay,
  },
  computed: {
    ...mapState(['showOverlay']),
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
  }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
