import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  status: '',
  token: localStorage.getItem('token') || '',
  priority: localStorage.getItem('priority') || '',
  user: {},
  showOverlay: false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  auth_request(state) {
    state.status = 'loading'
  },
  auth_success(state, token, user) {
    state.status = 'success'
    state.token = token
    state.user = user
  },
  auth_error(state) {
    state.status = 'error'
  },
  logout(state) {
    state.status = ''
    state.token = ''
  },
  SET_OVERLAY(state, payload) {
    state.showOverlay = payload;
  },
}

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/login`, user)
        .then(resp => {
          if (resp.data.length == 0){
            alert('Username or Password wrong !!!!')
            return
          }
          if (resp.data[0].priority == 99) {
            alert('Your Username Suspend!!!')
            return
          }
          const token = resp.data[0].token
          const user = resp.data[0].user
          const priority = resp.data[0].priority
          localStorage.setItem('token', token)
          localStorage.setItem('priority', priority)
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', token, user)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('priority')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  loadUserPriority({ commit }) {
    return new Promise((resolve, reject) => {
      const priority = localStorage.getItem('priority')
      if (priority) {
        commit('set', ['priority', priority])
        resolve(priority)
      } else {
        reject('No priority found in localStorage')
      }
    })
  },
  showOverlay({ commit }) {
    commit('SET_OVERLAY', true);
  },
  hideOverlay({ commit }) {
    commit('SET_OVERLAY', false);
  },

}

const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  getPriority: state => state.priority
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})